import {
	HiOutlineViewGrid,
	HiOutlineCube,
	HiOutlineShoppingCart,
	HiOutlineUsers,
	HiOutlineDocumentText,
	HiOutlineAnnotation,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog
} from 'react-icons/hi'

import {
	MdOutlineManageAccounts
} from 'react-icons/md'

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/',
		icon: <HiOutlineViewGrid />,
		subkeys: []
	},
	{
		key: 'ip',
		label: 'IP',
		path: '/products',
		icon: <HiOutlineCube />,
		subkeys: [
			{
				key: 'pool',
				label: 'Pool',
				path: '/pool',
			},
			{
				key: 'addresses',
				label: 'Addresses',
				path: '/addresses',
			},
		]
	},
	{
		key: 'ppp',
		label: 'PPP',
		path: '/ppp',
		icon: <MdOutlineManageAccounts />,
		subkeys: [
		{
			key: 'pppServer',
			label: 'PPP Server',
			path: '/ppp-server',
		},
		{
			key: 'pppProfile',
			label: 'Profiles',
			path: '/ppp/profiles',
		}
	]
	},
	{
		key: 'dhcp',
		label: 'DHCP',
		path: '/dhcp',
		icon: <HiOutlineCube />,
		subkeys: [
			{
				key: 'dhcp-packages',
				label: 'Packages',
				path: '/dhcp/packages',
			},
			{
				key: 'dhcp-server',
				label: 'Server',
				path: '/dhcp/server',
			},
			{
				key: 'dhcp-network',
				label: 'Network',
				path: '/dhcp/network',
			},
		]
	},
	{
		key: 'customers',
		label: 'Customers',
		path: '/customers',
		icon: <HiOutlineUsers />,
		subkeys: []
	},
	{
		key: 'transactions',
		label: 'Transactions',
		path: '/transactions',
		icon: <HiOutlineDocumentText />,
		subkeys: []
	},
	{
		key: 'messages',
		label: 'Messages',
		path: '/messages',
		icon: <HiOutlineAnnotation />,
		subkeys: []
	},
	{
		key: 'settings',
		label: 'Settings',
		path: '/settings',
		icon: <HiOutlineCog />,
		subkeys: [{
			key: 'router',
			label: 'Router',
			path: '/router',
		},
		{
			key: 'sms',
			label: 'SMS',
			path: '/sms',
		},
		{
			key: 'mpesa',
			label: 'M-Pesa',
			path: '/mpesa',
		},
		{
			key: 'email',
			label: 'Email',
			path: '/email',
		}
	]
	},
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	{
		key: 'support',
		label: 'Help & Support',
		path: '/support',
		icon: <HiOutlineQuestionMarkCircle />
	}
]
