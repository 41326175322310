import React, { useEffect, useState } from 'react';

function PPPClientStatus({ serviceName }) {
  const [status, setStatus] = useState('inactive');

  useEffect(() => {
    const socket = new WebSocket('ws://19.168.25.6/backend_api/ws/ppp/service-status/');

    socket.onopen = () => {
      // Send the client service name to the server
      socket.send(JSON.stringify({ service_name: serviceName }));
    };

    socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      setStatus(data.status);  // Update the status whenever the server sends a message
    };

    socket.onclose = () => {
      console.log('WebSocket closed');
    };

    return () => {
      socket.close();
    };
  }, [serviceName]);

  return (
    <>{status}</>
  );
}

export default PPPClientStatus;
