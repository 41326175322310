import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import api from '../api';
import ErrorModal from './ErrorModal';

const EditRouter = ({ closeEditRouterModal, router }) => {
  const [name, setName] = useState(router.name);
  const [ipAddress, setIpAddress] = useState(router.ip);
  const [username, setUsername] = useState(router.username);
  const [password, setPassword] = useState(router.password);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [crtFile, setCertificate] = useState(null);
  const [keyFile, setKeyFile] = useState(null);
  const [error, setError] = useState('');

  const handleCloseErrorModal = () => {
    setError('');
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('ip', ipAddress);
    formData.append('username', username);
    formData.append('password', password);
    if (crtFile) formData.append('certificate', crtFile);
    if (keyFile) formData.append('decrypted_key', keyFile);

    try {
        const response = await api.post('/api/router/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        if (response.status === 201) {
            closeEditRouterModal();
        }
    } catch (error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            const formattedError = Object.keys(errorData)
                .map((key) => `${key}: ${errorData[key]}`)
                .join(', ');

            setError(formattedError);
        } else {
            setError('An unexpected error occurred.');
        }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="fixed inset-0 flex items-start justify-end bg-gray-900 bg-opacity-50 z-50">
      <div className="w-full p-8 space-y-6 bg-white shadow-md rounded-lg max-w-sm h-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-center text-indigo-600">Edit Router Configuration</h2>
          <button
            onClick={closeEditRouterModal}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Router Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="ipAddress" className="block text-sm font-medium text-gray-700">IP Address</label>
            <input
              type="text"
              id="ipAddress"
              value={ipAddress}
              onChange={(e) => setIpAddress(e.target.value)}
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center px-3"
            >
              {passwordVisible ? <FaEyeSlash className="text-gray-500 mt-6" /> : <FaEye className="text-gray-500 mt-6" />}
            </button>
          </div>
          <div>
                        <label htmlFor="certificate" className="block text-sm font-medium text-gray-700">Certificate File (cert_export_api-webfig.crt)</label>
                        <input
                            type="file"
                            id="certificate"
                            onChange={(e) => setCertificate(e.target.files[0])}
                            className="block w-full mt-1"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="keyFile" className="block text-sm font-medium text-gray-700">Key File (cert_export_api-webfig_decrypted.key)</label>
                        <input
                            type="file"
                            id="keyFile"
                            onChange={(e) => setKeyFile(e.target.files[0])}
                            className="block w-full mt-1"
                            required
                        />
                    </div>
          <button
            type="submit"
            className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </form>
      </div>
      {error && <ErrorModal errorMessage={error} onClose={handleCloseErrorModal} />}
    </div>
  );
};

export default EditRouter;
