import React, { useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FcBullish } from 'react-icons/fc';
import { HiOutlineLogout, HiMenu, HiX } from 'react-icons/hi';
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../lib/constants';

const linkClass =
  'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base';

// SidebarLink Component
function SidebarLink({ link }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={link.path}
      className={classNames(
        pathname === link.path ? 'bg-neutral-700 text-white' : 'text-neutral-400',
        linkClass
      )}
    >
      <span className="text-xl">{link.icon}</span>
      {link.label}
    </Link>
  );
}

// SidebarDropdownLink Component
function SidebarDropdownLink({ link, isOpen, toggleDropdown }) {
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={toggleDropdown}
        className={classNames(
          pathname === link.path ? 'bg-neutral-700 text-white' : 'text-neutral-400',
          linkClass
        )}
      >
        <span className="text-xl">{link.icon}</span>
        {link.label}
      </div>
      {isOpen && (
        <div className="pl-7">
          {link.subkeys.map((subLink) => (
            <Link
              key={subLink.key}
              to={subLink.path}
              className={classNames(
                pathname === subLink.path ? 'bg-neutral-700 text-white' : 'text-neutral-400',
                linkClass
              )}
            >
              {subLink.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

// Sidebar Component
export default function Sidebar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = (key) => {
    if (openDropdown === key) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(key);
    }
  };

  return (
    <>
      {/* Menu button for smaller screens */}
      <button
        className="md:hidden p-3 text-white bg-neutral-900 fixed top-3 left-3 z-50"
        onClick={() => setIsSidebarOpen(true)}
      >
        <HiMenu fontSize={24} />
      </button>

      {/* Sidebar */}
      <div
        className={classNames(
          'bg-neutral-900 w-60 p-3 flex flex-col fixed z-40 h-full transition-transform transform',
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full',
          'md:relative md:translate-x-0 md:block'
        )}
      >
        {/* Sidebar Header */}
        <div className="flex items-center gap-2 px-1 py-3">
          <FcBullish fontSize={24} />
          <span className="text-neutral-200 text-lg">MikroTik API</span>
        </div>

        {/* Close button for smaller screens */}
        <button
          className="md:hidden absolute top-3 right-3 text-white"
          onClick={() => setIsSidebarOpen(false)}
        >
          <HiX fontSize={24} />
        </button>

        {/* Sidebar Links */}
        <div className="py-8 flex flex-1 flex-col gap-0.5">
          {DASHBOARD_SIDEBAR_LINKS.map((link) =>
            link.subkeys.length === 0 ? (
              <SidebarLink key={link.key} link={link} />
            ) : (
              <SidebarDropdownLink
                key={link.key}
                link={link}
                isOpen={openDropdown === link.key}
                toggleDropdown={() => toggleDropdown(link.key)}
              />
            )
          )}
        </div>

        {/* Bottom Links */}
        <div className="absolute bottom-0 left-0 right-0 p-3 border-t border-neutral-700">
          {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
            <SidebarLink key={link.key} link={link} />
          ))}
          <div
            className={classNames(linkClass, 'cursor-pointer text-red-500')}
            onClick={() => navigate('/logout')}
          >
            <span className="text-xl">
              <HiOutlineLogout />
            </span>
            Logout
          </div>
        </div>
      </div>

      {/* Overlay for smaller screens */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </>
  );
}
